import Vue from "vue";
import router from "../../router/pc";
import "normalize.css/normalize.css";
import "../../assets/reset.css";
import "../../assets/home/icon/remixicon.css";
import App from "./index.vue";
import "fullpage.js/vendors/scrolloverflow";
import VueFullpage from "vue-fullpage.js";
import "../../assets/icon/iconfont.css";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import animated from "animate.css";
Vue.use(animated);
Vue.use(Antd);

Vue.use(VueFullpage);

import { Collapse, CollapseItem } from "element-ui"; // 按需引入组件
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
