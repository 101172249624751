/*
 * @Author: xiexp
 * @Description: 
 * @Date: 2022-09-07 09:01:08
 * @LastEditTime: 2022-10-11 10:33:58
 * @FilePath: \indiba-web-new\src\router\pc.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import VueSuperSlide from "vue-superslide";

Vue.use(VueRouter);
Vue.use(VueSuperSlide);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/home",
  },
  {
    // 首页
    path: "/home",
    name: "home",
    component: () => import("../pages/pc/home.vue"),
  },
  {
    // 中国区
    path: "/china",
    name: "China",
    component: () => import("../pages/pc/china_new.vue"),
  },
  {
    // 中国区
    path: "/china_old",
    name: "China_old",
    component: () => import("../pages/pc/china.vue"),
  },
  {
    // 找到INDIBA
    path: "/found",
    name: "Found",
    component: () => import("../pages/pc/found_new.vue"),
  },
  {
    // 名人堂
    path: "/hall",
    name: "Hall",
    component: () => import("../pages/pc/hall.vue"),
  },
  {
    // 地图导航
    path: "/map",
    name: "Map",
    component: () => import("../pages/pc/map.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/pc",
  routes,
});

export default router;
